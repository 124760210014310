@font-face {
  font-family: 'Gore';
  src: local('Gore'), url(./fonts/Gore.otf) format('opentype');
}

body {
  background: #000;
  color: #fff;
  font-family: 'Gore', Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  position: absolute;
  top: 45%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.App h1 {
  font-size: 100px;
}

.App h2 {
  font-family:  Arial, sans-serif;
  font-size: 30px;
}

.App a {
  color: #fff;
}

.App a:hover {
  color: rgba(156, 163, 175);
}

.lg {
  display: block;
  margin: 0 auto;
  margin-top: 4rem;
  width: 10rem;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 770px) {
  .App h1 {
    font-size: 60px;
  }
  
  .App h2 {
    font-family:  Arial, sans-serif;
    font-size: 25px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
